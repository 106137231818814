body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.camgirlcont {
  float:left;
  margin:2px;
  border:1px solid black;
}

.camgirlscont {
  margin:2px auto;
  width:1200px;
  text-align:center;
}
.thumb {
  width:360px;
}
.clearBoth {
  clear:both;
}

@media (max-width: 820px) {
  .thumb{
    width:254px;
  }
  .camgirlscont{
    width:780px;
  }
}

@media (max-width: 480px) {
  .thumb{
    width:296px;
    margin:0px auto;
  }
  .camgirlscont{
    width:300px;
    margin:2px auto;
  }
}

